<template lang="">
  <div>
     <SubHeader :data="data" />
     <div class="container">
        <div class="content-about">
          <p>{{ $t(`AboutUs['aboutus_content']`) }}</P>
          <br/>
          <h1 style="font-weight: 500;">{{ $t(`AboutUs['withMosAppCan']`) }}:</h1>
          <ul class="list-style">
            <li>{{ $t(`AboutUs['subTitle1']`) }}</li>
            <li>{{ $t(`AboutUs['subTitle2']`) }}</li>
            <li>{{ $t(`AboutUs['subTitle3']`) }}</li>
            <li>{{ $t(`AboutUs['subTitle4']`) }}</li>
            <li>{{ $t(`AboutUs['subTitle5']`) }}</li>
            <li>{{ $t(`AboutUs['subTitle6']`) }}</li>
          </ul><br />
          <h1 style="font-weight: 500;">{{ $t(`AboutUs['OurTeam']`) }}:</h1>
          <p>{{ $t(`AboutUs['desc1']`) }}</p>
          <br/>
          <p>{{ $t(`AboutUs['desc2']`) }}</p>
          <br/>
          <p>{{ $t(`AboutUs['desc3']`) }}</p>
          <br/>
          <p>{{ $t(`AboutUs['desc4']`) }} </p>
          <br/>
          <p>{{ $t(`AboutUs['desc5']`) }} </p>
          <br/>
          <p>{{ $t(`AboutUs['desc6']`) }}</p>
          <br/>
          <p>{{ $t(`AboutUs['desc7']`) }}</p>
        </div>
     </div>
  </div>
</template>
<script>
import SubHeader from '../components/SubHeader.vue'
export default {
  name: 'About',
  components: {
    SubHeader
  },
  data() {
    return {
      data: {
        label_name: 'about',
        content_desc: 'aboutus_content',
        img: require('@/assets/image/sub-header/about-mos-app.png')
      }
    }
  }
}

</script>
<style lang="less" scoped>
.content-about {
    // font-size: 17px;
    // font-weight: 500;
    padding: 40px 0 40px;
    p {
      line-height: 30px;
    }
}
ul.list-style {
  margin-left: 40px;
    p {
      margin: 0;
    }
    li {
      list-style-type: '-  ';
      /* font-size: 16px; */
      padding: 5px 0;
    }
  }
</style>
